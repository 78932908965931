<template>
  <v-card>
    <v-toolbar color="orange darken-3" flat>
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-cog-outline</v-icon> Manage Category
      </v-toolbar-title>

      <v-spacer />

      <v-btn icon title="Click to close" @click="$emit('close')">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>

    <v-alert v-if="error" type="error" dismissible>
      {{ errorMessage }}
    </v-alert>

    <!-- Search field -->
    <v-card-text>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-text>

    <!-- List of categories -->
    <v-card-text>
      <p>List of all added categories:</p>
      <v-data-table
        :headers="[
          { text: 'Category Name', value: 'name' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ]"
        :items="filteredCategories"
        :items-per-page="-1"
        :loading="loadingProgress"
        :loading-text="loadingProgress ? 'Loading categories...' : ''"
        class="bordered"
        hide-default-footer
        dense
      >
        <template v-slot:item.actions="{ item, index }">
          <div style="min-width: 80px">
            <v-btn
              icon
              class="mr-2"
              color="teal"
              title="Click to edit"
              @click="editCategory(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              icon
              color="red"
              title="Click to delete"
              @click="deleteCategory(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider class="mt-5" />

    <!-- Form to create a new category -->
    <v-card-text>
      <v-form ref="form" @submit.prevent="saveCategory">
        <v-row>
          <v-col>
            <v-text-field
              v-model="newCategory.name"
              label="Category Name"
              placeholder="Enter category name"
              :rules="[rule.required]"
            />

            <v-autocomplete
              v-model="newCategory.file_types"
              :items="fileTypes"
              item-text="name"
              item-value="id"
              label="File Types"
              placeholder="Select file type"
              multiple
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn text color="teal" type="submit" :disabled="actionProgress">
              <v-progress-circular v-if="actionProgress" indeterminate />
              <span v-else>
                {{ editingCategory ? "Save Category" : "+ Add Category" }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import API from "@/plugins/API";
import rule from "@/plugins/rule";

export default {
  data: () => ({
    error: null,
    errorMessage: "",
    loadingProgress: false,

    actionProgress: false,

    categories: [],
    fileTypes: [],
    rule: rule,
    search: "",

    editingCategory: false,

    newCategory: {
      name: "",
      file_types: [],
    },

    tempCategoryID: null,
  }),

  methods: {
    editCategory(category) {
      this.editingCategory = true;
      console.log(category.file_types)
      this.newCategory.name = category.name;
      this.newCategory.file_types = category.file_types;
      this.tempCategoryID = category.id;
    },

    async deleteCategory(category) {
      if (window.confirm("Are you sure you want to delete this category?")) {
        const index = this.categories.indexOf(category);

        this.categories.splice(index, 1);

        try {
          // API call to delete category
          const response = await API().delete(
            `api/internal-admin/file-cat/delete-category/${category.id}`
          );

          console.log("Status: ", response.status);
          console.log(response.data.message);
        } catch (error) {
          console.log(error);
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      } else {
        return;
      }
    },

    async saveCategory() {
      try {
        if (this.$refs.form.validate()) {
          this.actionProgress = true;

          if (this.editingCategory) {
            // API call to update category
            const response = await API().patch(
              "api/internal-admin/file-cat/edit-category",
              {
                id: this.tempCategoryID,
                category: this.newCategory,
              }
            );

            console.log("Status: ", response.status);
            console.log(response.data.message);

            const category = this.categories.find(
              (c) => c.id === response.data.updated_category.id
            );

            // Handle update action
            category.name = this.newCategory.name;
            category.file_types = this.newCategory.file_types;
            this.editingCategory = false;
            this.tempCategory = null;
          } else {
            // Create new category if not editing
            // API call to create a new category
            const response = await API().post(
              "api/internal-admin/file-cat/create-category",
              { category: this.newCategory }
            );

            console.log("Status: ", response.status);
            console.log(response.data.message);

            // Handle create action
            const newCategory = response.data.new_category;

            this.categories.push(newCategory);
          }

          this.newCategory.name = "";
          this.newCategory.file_types = [];

          this.$refs.form.resetValidation();
          this.actionProgress = false;
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
        this.actionProgress = false;
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    async getCategories() {
      try {
        this.loadingProgress = true;
        // API call to get all categories
        const res_1 = await API().get(
          "api/internal-admin/file-cat/get-all-categories"
        );

        console.log("Status: ", res_1.status);
        console.log(res_1.data.message);

        this.categories = res_1.data.categories;

        // API call to get all file types
        const res_2 = await API().get(
          "api/internal-admin/file-type/get-all-file-types"
        );

        console.log("Status: ", res_2.status);
        console.log(res_2.data.message);

        this.fileTypes = res_2.data.file_types;

        this.loadingProgress = false;
      } catch (error) {
        console.log(error);
        this.loadingProgress = false;
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  mounted() {
    this.getCategories();

    this.$nextTick(() => {
      if (this.editingCategory) {
        this.$refs.categoryNameInput.focus();
      }
    });
  },

  computed: {
    filteredCategories() {
      // Computed property to filter categories based on search term
      return this.categories.filter((category) =>
        category.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>
